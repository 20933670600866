import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import Button from "@bit/azheng.joshua-tree.button"

function InTheCommunity(props) {
  if (!props.post.inTheCommunity.hasThisSection) {
    return <div></div>
  }

  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-inverse"
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={0}
        leftColumn={
          <div className="column is-5">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.inTheCommunity.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.post.inTheCommunity.imageId}`}
              responsive
            />
          </div>
        }
        rightColumn={
          <div className="column community-text-block">
            {props.post.inTheCommunity.heading && (
              <h2>{props.post.inTheCommunity.heading}</h2>
            )}
            {props.post.inTheCommunity.subheading && (
              <h3>{props.post.inTheCommunity.subheading}</h3>
            )}
            <MarkdownViewer
              markdown={props.post.inTheCommunity.text}
              className="community-markdown"
            />
            {/* <ButtonGroupMap buttons={props.post.inTheCommunity.buttons} /> */}
            <ButtonGroup noReverse>
              <Button
                contained
                buttonText={props.post.inTheCommunity.buttonOne.buttonText}
                href={props.post.inTheCommunity.buttonOne.href}
              />
            </ButtonGroup>
          </div>
        }
      />
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-block"
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column community-image">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.inTheCommunity.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.post.inTheCommunity.imageId}`}
              responsive
            />
            {props.post.inTheCommunity.heading && (
              <h2>{props.post.inTheCommunity.heading}</h2>
            )}
            {props.post.inTheCommunity.subheading && (
              <h3>{props.post.inTheCommunity.subheading}</h3>
            )}
            <MarkdownViewer
              markdown={props.post.inTheCommunity.text}
              className="community-markdown"
            />
            <ButtonGroup noReverse>
              <Button
                contained
                buttonText={props.post.inTheCommunity.buttonOne.buttonText}
                href={props.post.inTheCommunity.buttonOne.href}
              />
            </ButtonGroup>{" "}
          </div>
        }
        rightColumn={<div></div>}
      />
    </>
  )
}

export default InTheCommunity
