import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "../../components/ImageMeta"

const TextTopSection = props => {
  const badges = [
    "APSC/DEV/homepage-badge-aaoms",
    "APSC/DEV/homepage-badge-aomcf",
    "APSC/DEV/homepage-badge-american-college-surgeons",
    "APSC/DEV/homepage-badge-caoms",
    "APSC/DEV/homepage-badge-iomfs"
  ]

  return (
    <section
      className="home-section top-down text-top-section color-back"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="columns">
        <div className="column is-4"></div>

        <div className="column">
          <div className="columns">
            <div className="column">
              <MarkdownViewer
                className="has-text-centered"
                markdown={props.post.homeSectionOne.heading}
              />
              <MarkdownViewer markdown={props.post.homeSectionOne.text} />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="badge-group">
                {badges.map(badge => {
                  return (
                    <ImageMeta
                      key={badge}
                      cloudName="nuvolum"
                      publicId={badge}
                      width="auto"
                      responsive
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <ButtonGroup isCentered noReverse={props.noReverse}>
                <Button
                  buttonText={props.post.homeSectionOne.buttonOne.buttonText}
                  contained
                  href={props.post.homeSectionOne.buttonOne.href}
                />
                <Button
                  contained
                  buttonText={props.post.homeSectionOne.buttonTwo.buttonText}
                  href={props.post.homeSectionOne.buttonTwo.href}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default TextTopSection
