import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"

import ImageMeta from "../../components/ImageMeta"

function ImageTextAOMS(props) {
  return (
    <section className="home-section text-picture custom-aoms">
      <div className="columns reverse-columns is-vcentered">
        <div className="column is-4"></div>
        <div className="column is-6 picture-column ">
          <div className="columns">
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId="AOMS/DEV/homepage-aahc-desktop"
                width="auto"
                responsive
                data-aos-duration="1200"
                data-aos="fade-left"
                className="full-width"
                data-aos-anchor-placement="center-bottom"
              />
            </div>
          </div>
        </div>
        <div className="column is-1 "></div>
        <div
          data-aos="fade-up"
          data-aos-duration="200"
          id="first-column"
          className="column text-column mobile-pad"
        >
          <p className="large">
            At Amarillo Oral & Maxillofacial Surgery, we care deeply about your
            safety and comfort, which is why we always strive to provide the
            highest quality of care in our state-of-the-art surgical facility.
            As a facility that has received accreditation from the Accreditation
            Association for Ambulatory Health Care (AAAHC), we can assure you
            that our facility is the most advanced and up to date for surgical
            treatment.
          </p>

          <div className="columns is-vcentered">
            <div className="column">
              <div className="aoms-logo-wrapper">
                <ImageMeta
                  cloudName="nuvolum"
                  className="aoms-logo"
                  publicId="AOMS/DEV/homepage-aaahc-logo"
                  width="auto"
                  responsive
                />
              </div>
            </div>
            <div className="column">
              <ButtonGroup isCenteredMobile alignLeft>
                <Button
                  external
                  buttonText="Learn More"
                  href="https://www.aaahc.org/"
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="column is-4"></div>
      </div>
    </section>
  )
}

export default ImageTextAOMS
