import React, { PureComponent } from "react"
import Lottie from "react-lottie"

export default class LottieControl extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isStopped: false,
      isPaused: false,
      speed: 1,
      direction: 1,
      startFrame: 0,
      endFrame: 0,
      toggleSegment: false,
      autoloadSegments: true,
      name: "scrollcta",
      scrollOfset: 0
    }
  }

  componentDidMount() {
    var that = this
    //that.setState({isPaused: false,name: "didmount"})
    window.addEventListener(
      "scroll",
      function () {
        // on page scroll
        //requestAnimationFrame(() => { lottie.play("ninja")  }); // call parallaxScroll() on next available screen paint
        //that.setState({isPaused: false,name: "onscroll222"})
      },
      false
    )

    function cutoutOnScroll() {
      // console.log(that.props.scrollOfset);
      if (that.state.isStopped && that.state.scrollOfset <= 101) {
        that.setState({
          isStopped: false,
          isPaused: false,
          name: "onscroll: isStopped+isPaused"
        })
        // console.log("cutoutOnScroll");
      } else if (that.state.isPaused && that.state.scrollOfset <= 101) {
        that.setState({
          isPaused: false,
          isStopped: false,
          name: "onscroll: isPaused"
        })
        // console.log("cutoutOnScroll");
      }
      if (that.props.scrollOfset >= 102) {
        that.setState({
          isStopped: true,
          isPaused: true,
          name: "onscroll: passed window view",
          toggleSegment: false
        })
      }
    }

    window.addEventListener("scroll", cutoutOnScroll, false)
  }

  loadEvent = obj => {
    // console.log("Loaded: "+ "endFrame: "+this.state.endFrame + "startFrame: "+this.state.startFrame)
    //this.setState({ startFrame: 40, endFrame: 60 })
    this.setState({ loop: false })
    //this.setState({isPaused: false,})
  }

  handleEvent = obj => {
    // if (!this.props.loop) {
    //   if (obj.currentTime === (obj.totalTime - 1)) {
    //     if (this.state.isComplete) {
    //       //this.setState({ isStopped: true, isComplete: false })
    //       //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
    //       console.log("testing Time")
    //     } else {
    //       //this.setState({ isStopped: false, isComplete: true })
    //       //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
    //       console.log("testing Time")
    //     }
    //   }
    //console.log("Segment: "+ "endFrame: "+this.state.endFrame + "startFrame: "+this.state.startFrame)
    if (
      obj.currentTime >= 55 &&
      !this.state.toggleSegment &&
      this.state.scrollOfset <= 101
    ) {
      this.setState({ isPaused: true, toggleSegment: true })
      //this.setState({ startFrame: 40, endFrame: 60 })
      //this.setState({isStopped: false,isPaused: false,})
      //this.setState({isPaused: false,})
      //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
      //console.log("!toggleSegment 39/40!"+ "endFrame: "+this.state.endFrame + "startFrame: "+this.state.startFrame)
      //this.setState({ startFrame: 40, endFrame: 60, toggleSegment: true})
      // this.setState({isStopped: true})
      // this.setState({ startFrame: 40, endFrame: 60 })
      // this.setState({isStopped: false, toggleSegment: true})
      // this.setState({ startFrame: 40, endFrame: 60 })
      //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
    } else if (obj.currentTime >= 39 && obj.toggleSegment) {
      //this.setState({ startFrame: 0, endFrame: 126})
      //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
      //console.log("toggleSegment 39/40!"+ "endFrame: "+obj.endFrame + "startFrame: "+obj.startFrame)
      //this.setState({isStopped: false})
      //console.log("currentTime:"+obj.currentTime+"totalTime:"+obj.totalTime)
    }
    //}
  }

  resetEvent = obj => {
    this.setState({ isStopped: true, toggleSegment: false })
  }

  render() {
    const animationData = require("./scrollcta.json")

    const divStyle = {
      position: "absolute",
      zIndex: 100,
      width: "100%",
      height: "100%",
      top: "0px",
      pointerEvents: "none"
    }

    const {
      isStopped,
      isPaused,
      direction,
      speed,
      startFrame,
      endFrame
    } = this.state

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    }

    return (
      <div style={divStyle}>
        <Lottie
          options={defaultOptions}
          height={"100%"}
          width={"100%"}
          isStopped={isStopped}
          isPaused={isPaused}
          speed={speed}
          name={"ninja"}
          segments={[startFrame, endFrame]}
          direction={direction}
          eventListeners={[
            {
              eventName: "enterFrame",
              callback: obj => this.handleEvent(obj)
            },
            {
              eventName: "DOMLoaded",
              callback: obj => this.loadEvent(obj)
            },
            {
              eventName: "complete",
              callback: obj => this.resetEvent(obj)
            }
          ]}
        />
      </div>
    )
  }
}
