import React from "react"
import PropTypes from "prop-types"

import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "./ImageMeta"

function Video(props) {
  return (
    <TheaterVideo
      videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
      controls
      playing
      hasWatchVideo
      overVideo
      iconOnly
      language={props.language}
    >
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.imageId}
        data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.imageId}`}
        responsive
      />
    </TheaterVideo>
  )
}

Video.propTypes = {
  youtube: PropTypes.string.isRequired,
  language: PropTypes.string,
  imageId: PropTypes.string.isRequired
}

export default Video
