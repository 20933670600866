import React, { useState } from "react"

import "./ProgramToast.scss"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const ProgramToast = ({ closeDate, children }) => {
  if (!children) return null

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(closeDate)

  if (close || today > end) return null

  return (
    <div className="program-toast">
      <span className="close-toast" onClick={() => setClose(true)}>
        ✖
      </span>
      <MarkdownViewer markdown={children} />
    </div>
  )
}

export default ProgramToast
