import { Link, navigateTo, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class CloseIcon extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var closeClass = classNames({
      "c-menu": true
    })

    return (
      <div className={closeClass} onClick={this.props.onClick}>
        <span className="open"></span>
        <span className="open"></span>
      </div>
    )
  }
}

CloseIcon.propTypes = {}

export default CloseIcon
